import { Injectable } from '@angular/core';
import {catchError, map, tap} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable,throwError } from 'rxjs';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  
  httpOptions : any;

  public HOST: string = environment.backendUrl;
  public SDK_HOST: string = environment.sdkUrl;


  constructor(private http: HttpClient) {
    // Http Headers
    this.httpOptions = {
      headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('key', 'AIzaSyBvixSE7dn-2iaSDLrsyj3cqmwUOHl3LZk')

    }
  }

  get(url:string,params: HttpParams = new HttpParams()):Observable<any>{
    this.httpOptions.params = params;
    return this.http.get(url, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  send(object: any, url:string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
    .pipe(
        catchError(this.handleError)
    );
  }

  public handleError (error: HttpErrorResponse | any) {
    if (error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred: ', error.message ? error.message : error.toString());
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
            if(error.error != undefined){
                console.log(`Backend returned code: ${error.status} - ${error.statusText}, body was: ${error.message}`);
                return throwError(error.error);
            }else{
                return throwError(error);
            }
            
    }
    console.log('Raw error:', error);
    return throwError(error);
  }

  suscribe(object: any, url:string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
    .pipe(
        catchError(this.handleError)
    );
  }

  post(object: any, url: string): Observable<any> {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('key', 'AIzaSyBvixSE7dn-2iaSDLrsyj3cqmwUOHl3LZk')
    return this.http.post(url, object, {
      headers: headers,
      withCredentials: true // Si el backend necesita autenticación con cookies o credenciales
    }).pipe(
            map(this.extractData),
            catchError(this.handleError)
        );
  }

  public extractData(res: any) {
    if(res !== null){
      return res.result;
    }
    return res;
  }

  auth(object: any, url:string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
    .pipe(
        catchError(this.handleError)
    );
  }

  put(object: any, url:string): Observable<any> {
    return this.http.put(url, object ,this.httpOptions)
    .pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
  

}
