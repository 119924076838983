
<div class="checkout-container" [ngClass]="themeService.currentPalette">
    <!-- Formulario de checkout -->
    <div class="checkout-form-block">
        <form [formGroup]="form" >
            <div class="form-group">

                <label for="tarjeta">CreditCard holder name:</label>
                <input [formControl]="form.controls['nameHolderCreditCard']" type="text" id="tarjeta" name="cc-name" placeholder="CreditCard holder name" required>
                <small
                        *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched"
                        class="form-error-msg"> CreditCard holder name is required </small>
            </div>
            <div class="form-group">
                <label for="numero-tarjeta">CreditCard number:</label>
                <input
                        (input)="onCreditCardInput(); formatCreditCardNumber()"
                        (keypress)="onlyNumberKey($event)"
                        [formControl]="form.controls['numberCreditCard']"
                        type="text"
                        id="numero-tarjeta"
                        name="cc-number"
                        placeholder="CreditCard number"
                        required
                />
                <small
                        *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched"
                        class="form-error-msg"
                >
                    CreditCard number ir required
                </small>
                <small
                        *ngIf="form.controls['numberCreditCard'].hasError('invalidLength') && form.controls['numberCreditCard'].touched"
                        class="form-error-msg"
                >
                    CreditCard number must have 16 digits.
                </small>
                <small
                        *ngIf="form.controls['numberCreditCard'].hasError('invalidCardNumber') && form.controls['numberCreditCard'].touched"
                        class="form-error-msg"
                >
                    CreditCard number not valid
                </small>
            </div>


            <div class="datos-mac">
                <div class="form-group">
                    <label for="mes-expiracion">Expiration Month:</label>
                    <select [formControl]="form.controls['expMonthCreditCard']" id="mes-expiracion" name="mes-expiracion" required>
                        <option value="" disabled selected>Select Month</option>
                        <option value="01" id="mes-exp-0">January</option>
                        <option value="02" id="mes-exp-1">February</option>
                        <option value="03" id="mes-exp-2">March</option>
                        <option value="04" id="mes-exp-3">April</option>
                        <option value="05" id="mes-exp-4">May</option>
                        <option value="06" id="mes-exp-5">June</option>
                        <option value="07" id="mes-exp-6">July</option>
                        <option value="08" id="mes-exp-7">August</option>
                        <option value="09" id="mes-exp-8">September</option>
                        <option value="10" id="mes-exp-9">October</option>
                        <option value="11" id="mes-exp-10">November</option>
                        <option value="12" id="mes-exp-11">December</option>
                    </select>
                    <small
                            *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched"
                            class="form-error-msg">Month is required</small>
                </div>
                <div class="form-group">
                    <label for="ano-expiracion">Expiration Year:</label>
                    <select [formControl]="form.controls['expYearCreditCard']" id="ano-expiracion" name="ano-expiracion" required>
                        <option value="" disabled selected>Select Year</option>
                        <option value="24" id="ano-exp-2">2024</option>
                        <option value="25" id="ano-exp-3">2025</option>
                        <option value="26" id="ano-exp-4">2026</option>
                        <option value="27" id="ano-exp-5">2027</option>
                        <option value="28" id="ano-exp-6">2028</option>
                        <option value="29" id="ano-exp-7">2029</option>
                        <option value="30" id="ano-exp-8">2030</option>
                        <option value="31" id="ano-exp-9">2031</option>
                        <option value="32" id="ano-exp-10">2032</option>
                        <option value="33" id="ano-exp-11">2033</option>
                        <option value="34" id="ano-exp-12">2034</option>
                        <option value="35" id="ano-exp-13">2035</option>
                        <option value="36" id="ano-exp-14">2036</option>
                        <option value="37" id="ano-exp-15">2037</option>
                        <option value="38" id="ano-exp-16">2038</option>
                        <option value="39" id="ano-exp-17">2039</option>
                        <option value="40" id="ano-exp-18">2040</option>
                    </select>
                    <small
                            *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched"
                            class="form-error-msg">Year is required</small>
                </div>
                <div class="form-group">
                    <label for="cvv">CVV:</label>
                    <input maxlength="3"  (keypress)="onlyNumberKey($event)" (input)="onCVVInputChange()" [formControl]="form.controls['cvv']" type="password" id="cvv" name="cvv" placeholder="CVV" required>
                    <small
                            *ngIf="form.controls['cvv'].hasError('required') && form.controls['cvv'].touched"
                            class="form-error-msg"> CVV is required </small>
                </div>
            </div>
            <button style=" display: block;
    width: 40%;
    margin: 0 auto;" (click)="tokenize()" class="btn-submit">Save card</button>

        </form>
    </div>
</div>
  