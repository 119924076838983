import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ThemeService} from "../theme.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../services/loader.service";
import {NotificationService} from "../services/notification.service";
import * as CryptoJS from 'crypto-js';
import {WebService} from "../services/web.service";
import {FacPopupComponent} from "../components/fac-popup/fac-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-tokenize',
  templateUrl: './tokenize.component.html',
  styleUrls: ['./tokenize.component.scss']
})
export class TokenizeComponent implements OnInit{

  form: any;
  isManualInput: boolean = true;
  isAutoFilling: boolean = false;
  code : any;
  email : any;
  phone : any;
  keyAes : any;
  partner : any
  private creditCardId: any;
  private apply3ds: boolean;
  private tokenCreditCard: any;
  private openLoader(): void {
    console.log('Opening loader...'); // Ejemplo de registro adicional
    this.loader.open();
  }
  private closeLoader(): void {
    console.log('Closing loader...${reason}'); // Ejemplo de registro adicional
    this.loader.close();
  }

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      public themeService: ThemeService,
      private loader: LoaderService,
      private notification: NotificationService,
      private webService : WebService,
      private dialog: MatDialog,
      private sanitizer: DomSanitizer
  ) {
    this.form = new FormGroup({
      nameHolderCreditCard: new FormControl('', [Validators.required]),
      numberCreditCard: new FormControl('', [Validators.required, Validators.pattern(/^[0-9-]*$/), this.validateCreditCardNumber]),
      cvv: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]),
      expMonthCreditCard: new FormControl('', [Validators.required]),
      expYearCreditCard: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    // this.setupMessageListener(); // Agregar el listener
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.email = params['email'] || '';
      this.phone = params['phone'] || '';
      this.partner = params['partner'];
      // Convertimos el string a booleano y establecemos true como valor por defecto
      this.apply3ds = params['apply3ds'] ? params['apply3ds'].toLowerCase() === 'true' : true;


      if (!this.code) {
        // Redirigir o manejar el error si code no está presente
        return this.notification.showError("code queryParam is required");
      }else if(!this.partner){
        return this.notification.showError("partner queryParam is required")
      }

      this.openLoader()
      this.webService.get(this.webService.HOST + "/config/key/key_aes_encrypt").subscribe(res => {
        this.keyAes = res.result.value_config;
        this.closeLoader();
      }, error => {
        this.closeLoader();
        this.notification.showError(error);
      })
    });
  }

  tokenize() {
    this.openLoader(); // Abrir el loader al inicio del proceso
    if (!this.form.valid) {
      console.log('Formulario no válido. Revisa los campos.');
      this.closeLoader();
      return this.notification.showError("Please complete the formulary...");
    }

    // Split name holder into first and last name
    const fullName = this.form.get('nameHolderCreditCard').value.trim();
    let firstName = fullName;
    let lastName = fullName;

    if (fullName.includes(' ')) {
      const nameParts = fullName.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' '); // Join remaining parts as lastname
    }

    // Create the JSON structure
    const tokenizeData = {
      creditcard: {
        numberCreditCard: this.form.get('numberCreditCard').value.replace(/[-\s]/g, ''), // Remove spaces
        nameHolderCreditCard: this.form.get('nameHolderCreditCard').value,
        expMonthCreditCard: this.form.get('expMonthCreditCard').value,
        expYearCreditCard: this.form.get('expYearCreditCard').value,
        cvv: parseInt(this.form.get('cvv').value) // Convert to number
      },
      customer: {
        name: firstName,
        lastname: lastName,
        email: this.email,
        phone: this.phone,
        member: this.code
      }
    };

    try {
      // Convertir el objeto a string JSON
      const jsonString = JSON.stringify(tokenizeData);

      // Convertir la clave a formato UTF-8
      const keyUtf8 = CryptoJS.enc.Utf8.parse(this.keyAes);

      // Realizar la encriptación AES
      const encrypted = CryptoJS.AES.encrypt(jsonString, keyUtf8, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

      // Obtener el resultado en base64
      const encryptedBase64 = encrypted.toString();

      let queryParams = "?apply3ds=" + this.apply3ds ;
      if(!this.apply3ds){ // EN CASO DE QUE SE DECIDA NO APLICAR 3DS ENTONCES PROCEDO A ANEXAR EL applyCharge
        queryParams = queryParams.concat("&applyCharge=true")
      }

      this.webService.post({data:encryptedBase64}, this.webService.SDK_HOST + "/partner/"  + this.partner + "/creditcard" + queryParams).subscribe(response => {
        this.closeLoader();

        this.creditCardId = response.id;
        this.tokenCreditCard = response.token;
        if(response.htmlForm){
          this.openFacPopup(response.htmlForm);
        }else {
          this.sendTokenToParent()
          this.router.navigateByUrl("/ok");
        }
      },error => {
        console.log(error);
        this.notification.showError(error.responseCodeDescription);
        this.closeLoader();
      })

    } catch (error) {
      console.error('Error en la encriptación:', error);
      this.closeLoader();
      this.notification.showError("Error en el proceso de tokenización");
    }
  }

  validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
    const value = control.value.replace(/-/g, ''); // Eliminar guiones para validación

    if (!value || !this.isManualInput) return null; // No aplicar validación si no es entrada manual

    // Comprobar si tiene exactamente 16 dígitos
    if (value.length !== 16) {
      return { invalidLength: true };
    }

    // Validar usando el Algoritmo de Luhn
    if (!this.luhnCheck(value)) {
      return { invalidCardNumber: true };
    }

    return null;
  };

  // Algoritmo de Luhn para validar el número de tarjeta
  luhnCheck(cardNumber: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return (sum % 10) === 0;
  }

  onCreditCardInput(): void {
    this.isManualInput = true; // Activar validación cuando el usuario escribe manualmente
  }

  formatCreditCardNumber(): void {
    if (!this.isManualInput) return; // Salir si no es entrada manual

    const control = this.form.get('numberCreditCard');
    let value = control.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

    if (value.length > 16) {
      value = value.substring(0, 16); // Limitar a 16 caracteres
    }

    const formattedValue = value.match(/.{1,4}/g)?.join('-') || value;
    control.setValue(formattedValue, { emitEvent: false });
  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCVVInputChange(): void {
    if (!this.isAutoFilling) {
      console.log('El usuario ingresó el CVV, pero no se cambia a modo manual.');
    } else {
      console.log('El CVV fue ingresado automáticamente.');
    }
  }
  private openFacPopup(htmlFormData: string): void {

      // Mostrar spinner por 4 segundos
      this.showLoader(); // Abre el loader por encima de todo
      //console.log('lod',this.showLoader);
      // Añadir el fondo al body
      const backdrop = document.createElement('div');
      backdrop.className = 'custom-backdrop';
      document.body.appendChild(backdrop);

      const dialogRef = this.dialog.open(FacPopupComponent, {
        width: '600px',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(htmlFormData), creditCardId: this.creditCardId },
        panelClass: 'custom-dialog-container'
      });

      dialogRef.afterClosed().subscribe(() => {
        this.getCreditCard();
        document.body.removeChild(backdrop); // Remover el fondo después de cerrar el popup
      });


    }

  private getCreditCard() {
    const url = `${this.webService.HOST}/creditcard/${this.creditCardId}`;
    // Make an HTTP GET request to check the plan status
    this.webService.get(url).subscribe(
        response => {
          // Close the loader once the response is received
          this.closeLoader();

          // Check the status of the customer's plan
          const status = response.result.verified;
          if (status) { // ACTIVO O ESPERAND A SER COBRADA PERO OK
            // Navigate to the congratulation page
            this.sendTokenToParent()
            this.router.navigateByUrl("/ok");
          } else {
            // Show an error notification if the payment was rejected
            this.notification.showError("Your creditCard was refused by the bank, please try again.");
          }
        },
        error => {
          // Close the loader if an error occurs
          console.log(error);
          this.closeLoader();
          // Show an error notification with the error message
          this.notification.showError("There was an error creating creditCard. Please contact with the administrator.");
        }
    );
  }

  private showLoader(): void {
    // Establecer estilos para el loader
    const loaderElement = document.createElement('div');
    loaderElement.className = 'custom-loader'; // Clase CSS personalizada para el loader
    loaderElement.style.position = 'fixed';
    loaderElement.style.top = '50%';
    loaderElement.style.left = '50%';
    loaderElement.style.transform = 'translate(-50%, -50%)';
    loaderElement.style.zIndex = '999999'; // Z-index alto para estar por encima de todo
    loaderElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'; // Fondo semi-transparente
    loaderElement.innerHTML = `
        <div class="spinner"> </div>
    `;
    document.body.appendChild(loaderElement);

    // Cerrar el spinner después de 4 segundos
    setTimeout(() => {
      document.body.removeChild(loaderElement);
    }, 8000);
  }

  private validateToken(token: string): boolean {
    // Implementa las validaciones específicas que necesites
    return token && token.length > 0;
  }

  sendTokenToParent() {
    try {
      console.log(this.tokenCreditCard);
      if (!this.tokenCreditCard) {
        this.notification.showError('Token no disponible');
        return;
      }

      if (!this.validateToken(this.tokenCreditCard)) {
        this.notification.showError('Token inválido');
        return;
      }

      window.parent.postMessage(
          { type: 'TOKEN_RESPONSE', token: this.tokenCreditCard },
          '*'
      );

      this.loader.open();
      setTimeout(() => {
        this.loader.close();
        this.notification.showSuccess('Token enviado correctamente');
      }, 1000);
    } catch (error) {
      this.notification.showError('Error al enviar token');
      console.error('Error:', error);
    }
  }

  // Agregar listener para respuestas del padre
  private setupMessageListener() {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'TOKEN_RESPONSE') {
        console.log('Confirmación recibida del padre:', event.data);
        this.notification.showSuccess('Token recibido por el padre');
      }
    });
  }


}
