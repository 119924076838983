import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PracticComponent } from './practic/practic.component';
import { CheckoutComponent } from './checkout/checkout.component'; 
import { CongratulationComponent } from './components/congratulation/congratulation.component';
import {UpdatePaymentSuccessComponent} from "./components/update-payment-success/update-payment-success.component";
import {TokenizeComponent} from "./tokenize/tokenize.component";
import {SuccessComponent} from "./components/success/success.component";

const routes: Routes = [
  { path: "", component: CheckoutComponent },
  { path: 'preview/:id', component: PracticComponent },
  { path: "checkout", component: CheckoutComponent},
  { path: 'preview/:id/checkout', component: CheckoutComponent },
  {path: "congratulation", component:CongratulationComponent},
  {path: "success", component:UpdatePaymentSuccessComponent},
  {path: "ok", component:SuccessComponent},
  { path: "payment/tokenize", component: TokenizeComponent, data: {
      requiredParams: ['code','partner']
    }}
  // Otras rutas si son necesarias
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations:[]
})
export class AppRoutingModule { }
