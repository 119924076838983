import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../theme.service';

@Component({
  selector: 'app-congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss']
})
export class CongratulationComponent implements OnInit {
  countdown: number = 5;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    public themeService: ThemeService
  ) {}

  ngOnInit() {
    // Capturar el idioma desde los parámetros de la URL y aplicarlo
    this.route.queryParams.subscribe(params => {
      const language = params['language'] || 'es'; // Español como predeterminado si no se pasa ningún idioma
      this.translate.use(language);
      console.log(`Idioma configurado: ${language}`);
    });

    // Contador regresivo (opcional, si todavía lo necesitas)
   // const counter = interval(1000).pipe(take(this.countdown));
    // counter.subscribe(
    //   (value) => this.countdown = this.countdown - 1,
    //   (err) => console.error(err),
    //   () => console.log('Fin del contador')
    // );
  }

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
}
